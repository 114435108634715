import adminInterface from '@/interface/baseApi'
export function getThirdInfo(data) {
    return adminInterface({
        url: '/oms/share/thirds/getThirdCff',
        method: 'post',
        data
    })
}
export function getFreeCoupon(data) {
    return adminInterface({
        url: '/oms/share/thirds/getFreeCoupon',
        method: 'post',
        data
    })
}
export function getSaleCoupon(data) {
    return adminInterface({
        url: '/oms/coupon/sale/get',
        method: 'post',
        data
    })
}
export function getDetail(data) {
    return adminInterface({
        url: '/oms/coupon/operate/detail',
        method: 'post',
        data
    })
}

export function getUuid(data) {
    return adminInterface({
        url: '/auth/getUuid',
        method: 'post',
        data
    })
}
export function addLog(data) {
    return adminInterface({
        url: '/oms/report/logs/add',
        method: 'post',
        data
    })
}

export function truckInitGet(data) {
    return adminInterface({
        url: '/wangshangdai/customer/getWxNewClientPageCfg',
        method: 'post',
        data
    })
}







