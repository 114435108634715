<template>
    <div class="page" >
        <div class="header">
            <div class="bg-img-box">
                <img class="bg-img" src="http://images.yuntruck.com/default/202305/1684807135648.jpg" mode=""/>
                <img class="bg-logo-img" src="http://images.yuntruck.com/default/202305/1684807126182.png" mode=""/>
            </div>
        </div>
        <div class="content">
            <!-- 二维码start -->
            <div class="Qrcode">
                <div class="Qrcode-bg-box">
                    <img class="Qrcode-bg-img" src="http://images.yuntruck.com/default/202302/1676339760154.png"
                         mode=""/>
                    <div class="Qrcode-content">
                        <div class="Qrcode-text">
                            <div class="title">
                                添加客服答疑
                            </div>

                            <div class="title-intro">
                                进群更有
                                <span style="color:red">超多福利</span>
                            </div>
                            <div class="qrcode-note">
                                (长按识别二维码)
                            </div>
                            <div @click="handleGoMiniProgram">
                                <img class="shopImg" src="../../../assets/images/activity/shop.png"/>
                            </div>
                        </div>
                        <div class="Qrcode-img-box">
                            <img class="Qrcode-img"
                                 :src="thirdsInfo.qrcode"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 二维码end -->
            <!-- 免费领券start -->
            <div class="coupon" v-if="mianfeiCouponList.length>0">
                <div class="coupon-content">
                    <div class="coupon-bg-title">
                        <div class="coupon-bg-title-img-box">
                            <img src="../../../assets/images/activity/img_title_coupon.png" class="coupon-bg-title-img"
                                 mode=""/>
                        </div>
                        <div class="coupon-bg-title-content">
                            {{mianfeiInfo.name}}
                        </div>
                        <div class="coupon-item">
                            <div class="item" v-for="(item,idx) in mianfeiCouponList" :key="idx">
                                <div class="item-img-box">
                                    <img src="../../../assets/images/activity/img_coupon.png" class="item-img" mode=""/>
                                    <div class="item-title">
                                        {{item.name}}
                                    </div>
                                    <div class="item-price">
                                        <div class="item-price-box">
                                            <div>
                                                <span class="item-price-num"> {{item.money}}</span>

                                                <span class="item-price-text">元</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-name">
                                        机油券
                                    </div>
                                </div>
                            </div>
                            <div class="coupon-content-bottom-btn">
                                    <div v-if="infoIsGet == false" @click="handleGoMiniProgram">
                                        <img class="coupon-content-bottom-btn-img"
                                             src="../../../assets/images/activity/img_btn_collect.png" mode=""/>
                                    </div>
                                    <div v-if="infoIsGet " @click="handleGoMiniProgram">
                                        <img class="coupon-content-bottom-btn-img"
                                             src="../../../assets/images/activity/yilingqu.png" mode=""/>
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 免费领券end -->
            <!-- 限时秒杀start -->
            <div class="coupon" v-if="couponMkillItem.length > 0">
                <div class="coupon-content">
                    <div class="coupon-bg-title">
                        <div class="coupon-bg-title-img-box">
                            <img src="../../../assets/images/activity/img_title_buying.png" class="coupon-bg-title-img"
                                 mode=""/>
                        </div>
                        <div class="coupon-bg-title-content">
                            抢购时间<span class="time-d">{{leftTimeList[0]}}</span>天<span
                                class="time-d">{{leftTimeList[1]}}</span>:<span
                                class="time-d">{{leftTimeList[2]}}</span>:<span
                                class="time-d">{{leftTimeList[3]}}</span>结束
                        </div>
                        <div class="bg-miao-img-box" v-for="(item,index) in couponMkillItem" :key="index">
                            <img class="bg-miao-img" src="../../../assets/images/activity/bg_wrap_buying.png" mode=""/>
                            <div class="miao-content">
                                <div class="miao-l">
                                    <img v-if="item.showImg==''" class="miao-l-img"
                                         src="../../../assets/images/activity/empty.png" mode=""/>
                                    <img v-else class="miao-l-img" :src="item.showImg" mode=""/>
                                </div>
                                <div class="miao-m">
                                    <div class="miao-m-box">
                                        <div class="miao-title">
                                            {{item.name}}
                                        </div>
                                        <div class="miao-intro">
                                            {{item.slogan}}
                                        </div>
                                        <div class="miao-price">
                                            <span class="miao-price-icon">￥</span>
                                            <span class="miao-price-num">{{item.saleMoney}}</span>
                                            <span class="miao-price-unit">元</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="miao-r">
                                    <div class="miao-btn" @click="handleGoMiniProgram" v-if="!item.isGet">
                                        立即购买
                                    </div>
                                    <div class="miao-btn-else" @click="handleGoMiniProgram" v-else>
                                        已购买，去使用
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 限时秒杀end -->
            <!-- 保养推荐start -->
            <div class="coupon" v-if="couponMakeupItem.length > 0">
                <div class="coupon-content">
                    <div class="coupon-bg-title">
                        <div class="coupon-bg-title-img-box">
                            <img src="../../../assets/images/activity/img_title_recommend.png"
                                 class="coupon-bg-title-img" mode=""/>
                        </div>
                        <div class="recommend-item" v-for="(item,idx) in couponMakeupItem" :key="idx"
                             @click="handleGoMiniProgram" >
                            <div class="rec-l">
                                <div class="rec-l-img-box">
                                    <img v-if="item.showImg==''" class="rec-l-img"
                                         src="../../../assets/images/activity/empty.png" mode=""/>
                                    <img v-else class="rec-l-img" :src="item.showImg" mode=""/>
                                </div>
                            </div>
                            <div class="rec-m">
                                <div class="rec-m-box">
                                    <div class="rec-m-title">
                                        {{item.name}}
                                    </div>
                                    <div class="rec-m-intro">
                                        {{item.slogan}}
                                    </div>
                                    <div class="rec-m-intro">
                                        活动时间：{{item.startTime}} - {{item.endTime}}
                                    </div>
                                    <div class="rec-m-price">
                                        <div>
                                            <span class="miao-price-icon">￥</span>
                                            <span class="miao-price-num">{{item.saleMoney}}</span>
                                            <span class="miao-price-unit">元</span>
                                        </div>
                                        <div>
                                            <div class="rec-m-btn">
                                                立即购买
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="more-box" @click="handleGoMiniProgram">
                            <div class="more-text">
                                查看更多套餐
                            </div>
                            <div class="more-img-box">
                                <img src="../../../assets/images/activity/more.png" class="more-img"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 保养推荐end -->
            <!--活动说明start  -->
            <div class="description" >
                <div class="desc-title">
                    活动说明
                </div>
                <div class="desc-content">
                    <div class="desc-con-title">
                        一、活动提醒
                    </div>
                    <div class="desc-con-intro">
                        {{thirdsInfo.activityRemind}}
                    </div>
                    <div class="desc-con-title">
                        二、活动时间
                    </div>
                    <div class="desc-con-intro">
                        {{thirdsInfo.activityTime}}
                    </div>
                    <div class="desc-con-title">
                        三、活动规则
                    </div>
                    <div class="desc-con-intro">
                        <div class="intro-text">
                            1、活动时间内共计发放10000份优惠券，每位用户可免费领取一次价值1000元的机油抵扣券，该券可在云卡商城内购买单桶机油券时进行金额抵扣；
                        </div>
                        <div class="intro-text">
                            2、本次的秒杀品仅可在本页面参与，每个秒杀单品用户仅可参与一次秒杀机会，每个秒杀单品限量500份，先抢先得，秒完为止，秒杀券不能与平台其他优惠券叠加使用；
                        </div>
                        <div class="intro-text">
                            3、购买到的机油券，用户可参与线上预约保养项目，到线下云卡合作门店提货或参与车辆保养时使用；
                        </div>
                        <div class="intro-text">
                            4、此活动页面内包含的各类卡券，均来自云卡提供，无法兑换成现金，仅可在云卡商城内使用；
                        </div>
                        <div class="intro-text">
                            5、 购买到的券将无法进行退款，请仔细阅读活动说明及产品信息；
                        </div>
                        <div class="intro-text">
                            6、 活动页内容最终解释权归云卡商城所有。
                        </div>
                    </div>
                    <div class="desc-con-title">
                        四、活动常见问题
                    </div>
                    <div class="desc-con-intro">
                        <div class="intro-text">
                            1、 如何使用优惠券？
                            领取完优惠券之后，点击<span style="font-weight: bolder;font-size:1.2rem">【已领取去使用】</span>，在我的券包里选择要使用的优惠券，点击
                            <text style="font-weight: bolder;font-size:1.2rem">【立即使用】</text>
                            即可进入下单界面，勾选优惠券购买该商品即可。
                        </div>
                        <div class="intro-text">
                            2、 如何预约线下门店提货或做保养？
                            在购买机油券后，进入我的券包，点击立即使用，会到达预约项目界面，选择您想要参与的项目类型以及配件方案，填写基础信息提交预约即可。
                        </div>

                    </div>
                </div>
            </div>
            <!-- 活动说明end -->
            <div class="bottom-logo-box">
                <img class="bottom-logo" src="../../../assets/images/activity/bg-bottom.png" mode=""/>
            </div>

        </div>

    </div>

</template>


<script>
    import {getThirdInfo, getFreeCoupon, getDetail, getSaleCoupon, getUuid,addLog,truckInitGet} from '@/interface/api/truckHome/home/index'
    export default {
        name: "index",
        data: function () {
            return {
                isWexin:true,
                //倒计时的测试
                time: 0,
                left_time: '', //活动剩余时间的秒数
                timer: '', //倒计时定时器
                leftTimeList: [], //剩余秒数转换 天，小时 分 秒
                lastTime: '',
                pageCode: 'couponQrcodeGroup',
                pageInfo: {
                    name: '',
                    defImg: '',
                    bgImg: '',
                },
                search: {
                    page: 1,
                    pageSize: 100,
                    keywords: '',
                    isActive: 2,
                    scene: 'mkill'
                },
                scene: 'couponScanQrcode',
                operateId: 0,
                isLogin: false,
                infoIsGet: false,
                isBindTel: false,
                operate: {
                    name: '',
                    bgImg: '',
                },
                couponItem: [],
                //页面标识
                code: 'huochezhijiaActivity',
                //第三方
                thirdsInfo: {},
                dataIds: {},
                mianfeiCouponList: [],
                mianfeiInfo: {},
                couponMkillItem: [],
                couponMakeupItem: [],
                infoId: '',
                timeOut: null,
                uuid:'',
            }
        },


        created() {
            //获取uuid
            let uuid = sessionStorage.getItem('uuid')
            if(uuid==''|| uuid==undefined){
                this.handleGetUuid()
            }else{
                this.uuid = uuid
                this.handleAddLog(uuid)
            }
            //增加addlog

            //获取第三方信息
            this.getThirdsInfo()
            //获取免费券
            this.getFreeCoupon()
            //
            // this.wxInit()
        },
        methods: {
            handleGoMiniProgram() {
                wx.miniProgram.navigateBack();
// 因为只有执行了返回动作 小程序那边才能捕获到这边传递的参数
                wx.miniProgram.postMessage({data:
                        {
                            appId: 'wx9ed82a18f13f2603',
                            thirdsAppId:'',
                            path: '/pages/thirds/trucks/trucks?appid=8866473987638',
                        }
                })
            },
            handleGetUuid() {
                getUuid()
                    .then(response => {
                        let res = response.response
                        this.uuid = res
                        this.handleAddLog(res)
                        sessionStorage.setItem('uuid', res)
                    }).catch(() => {
                })
            },
            handleAddLog(res) {
                let info = {
                    code: this.code,
                    appid: '8866473987638',
                    uuid: res,
                    params: '',
                    preCode: '',
                }
                addLog(info)
                    .then(response => {
                    }).catch(() => {
                })
            },

            getThirdsInfo() {
                let params = {
                    partner: 'huochezhijia'
                }
                getThirdInfo(params)
                    .then(response => {
                        if (response.status == true) {
                            let info = response.response
                            this.thirdsInfo = info
                        }

                    }).catch(() => {
                })
            },
            //获取组合ID
            getFreeCoupon() {
                let params = {
                    partner: 'huochezhijia'
                }
                getFreeCoupon(params)
                    .then(response => {
                        if (response.status == true) {
                            let list = response.response
                            this.dataIds = list
                            this.getDetailInfo(list.freeId)
                            this.getCouponMkillItem(list.miaoshaIds);
                            this.getCouponMakeupItem(list.productIds);
                        }

                    }).catch(() => {
                })
            },
            getDetailInfo(id) {
                if (id == '' || id == undefined) {
                    return
                } else {
                    let params = {
                        id: id
                    }
                    getDetail(params)
                        .then(response => {
                            if (response.status == true) {
                                let info = response.response.operateItems
                                let res = response.response
                                let arr = this.formatMoney(info)
                                this.mianfeiCouponList = arr
                                if (Object.keys(res).length > 0) {
                                    this.mianfeiInfo.name = res.name;
                                    this.infoIsGet = res.isGet;
                                }
                            }
                        }).catch(() => {

                    })
                }
            },
            //获取秒杀优惠
            getCouponMkillItem(ids) {
                console.log(ids)
                let id = ids.toString()
                let search = {
                    page: 1,
                    pageSize: 100,
                    keywords: '',
                    isActive: 2,
                    scene: 'mkill',
                    isShow: 2,
                    ids: id
                }
                this.getCouponList(search)
            },
            //获取组合优惠
            getCouponMakeupItem(ids) {
                let id = ids.toString()
                let search = {
                    page: 1,
                    pageSize: 100,
                    keywords: '',
                    isActive: 2,
                    scene: 'makeup',
                    isShow: 2,
                    ids: id
                }
                this.getCouponList(search);
            },
            getCouponList(search) {
                console.log(search)
                getSaleCoupon(search)
                    .then(response => {
                        console.log(response)
                        if (response.status == true) {
                            if (response.response.items.length > 0) {
                                let couponItem = [];
                                let lastTime = '';
                                let date = ''
                                response.response.items.forEach(function (item) {
                                    date = item.startDate + '-' + item.endDate
                                    lastTime = item.endTime;
                                    item.startTime = item.startTime.substring(0, 10).replaceAll('-', '.');
                                    item.endTime = item.endTime.substring(0, 10).replaceAll('-', '.');
                                    couponItem.push(item);
                                })
                                this.thirdsInfo.activityTime = date
                                let arr = couponItem
                                if (search.scene == 'mkill') {
                                    this.formatLastTime(lastTime)
                                    this.couponMkillItem = arr
                                } else if (search.scene == 'makeup') {
                                    this.couponMakeupItem = arr
                                } else {
                                    this.couponSaleItem = arr
                                }
                            }
                        }
                    }).catch(() => {

                })
            },
            //计算倒计时时间
            // eslint-disable-next-line vue/no-dupe-keys
            formatLastTime(lastTime) {
                lastTime = lastTime.replace(/-/g, '/');
                this.getLeftTime(lastTime)
            },
            //开始倒计时测试
            getLeftTime(end_time) {
                let left_time = this.getTimestap(end_time);
                this.initDate(left_time)
                this.timer = setInterval(() => {
                    if (left_time-- == 0) {
                        this.left_time = 0
                        this.leftTimeList = this.formatSeconds(0)
                        clearInterval(this.timer)
                    } else {
                        this.initDate(left_time)
                    }
                }, 1000);
            },
            /**
             * 初始化时间数据
             */
            initDate(e) {
                let leftTimeList = this.formatSeconds(e);
                this.left_time = e
                this.leftTimeList = leftTimeList
                this.leftDay = leftTimeList[0]
            },
            /**
             * 获取指定时间-当前时间的秒数
             * @param {*} end_time
             */
            getTimestap(end_time) {
                // 当前时间
                var currentTime = parseInt(new Date().getTime() / 1000);
                // 未来时间
                var futureTime = parseInt(new Date(end_time.replace(/-/g, '/')).getTime() / 1000); //ios无法解析
                return futureTime <= currentTime ? 0 : futureTime - currentTime;
            },
            /**
             * 毫秒-天-时-分-秒
             * @param {*} value
             */
            formatSeconds(value) {
                let time = [],
                    day = parseInt(value / 86400),
                    hour = parseInt((value % 86400) / 3600),
                    min = parseInt(((value % 86400) % 3600) / 60),
                    sec = parseInt(((value % 86400) % 3600) % 60);
                time[0] = day > 0 ? this.addZero(day) : this.addZero(0);
                time[1] = hour > 0 ? this.addZero(hour) : this.addZero(0);
                time[2] = min > 0 ? this.addZero(min) : this.addZero(0);
                time[3] = sec > 0 ? this.addZero(sec) : this.addZero(0);
                // console.log(time)
                return time;
            },
            /**
             * 补0
             * @param {*} num
             */
            addZero(num) {
                return num < 10 ? "0" + num : num;
            },
            getTimestap(end_time) {
                // 当前时间
                var currentTime = parseInt(new Date().getTime() / 1000);
                // 未来时间
                var futureTime = parseInt(new Date(end_time.replace(/-/g, '/')).getTime() / 1000); //ios无法解析
                return futureTime <= currentTime ? 0 : futureTime - currentTime;
            },
            //格式化钱数
            formatMoney(data) {
                data.forEach(item => {
                    item.money = parseInt(item.money)
                })
                return data
            },

        }
    }
</script>

<style scoped>
    .page {
        background-color: #E81600;
        height: auto;
    }

    .bg-logo-img {
        width: 19rem;
        height: 4.5rem;
        position: absolute;
        left: 6.5rem;
        top: 0px
    }

    .bg-img {
        position: relative;
        width: 100%;
        height: auto;
    }

    .qrcode-note {
        margin-top: 0.45rem;
        font-size: 1rem;
        color: #606266
    }

    .Qrcode-bg-box {
        position: relative;
    }

    .content {
        margin-left: 0.83rem;
        margin-right: 0.83rem;
    }

    .Qrcode-content {
        display: flex;
        width: 27rem;
        justify-content: space-between;
        padding: 1.4rem 1.1rem;
        top: 0rem;
        left: 0rem;
        position: absolute;
    }

    .Qrcode-bg-img {
        width: 29.5rem;
        height: 11rem;
    }

    .Qrcode-img {
        width: 8.6rem;
        height: 8.6rem;
    }

    .Qrcode-text {
        font-size: 1.7rem;
        font-weight: bolder;
    }

    .title {
        color: #332D2D;
    }

    .title-intro {
        font-size: 1.5rem;
        margin-top: 0.5rem;
        color: #282728;
    }

    .shopImg {
        margin-top: 0.65rem;
        width: 9.2rem;
        height: 2.1rem;
    }


    .coupon {
        margin-top: 1.6rem;
    }

    .coupon-content {
        width: 29.6rem;
        height: auto;
        border-radius: 1rem;
        background-color: #FFFFFF;
    }

    .coupon-item {
        display: flex;
        flex-wrap: wrap;
    }

    .coupon-bg-title {
        padding-bottom: 1.25rem;
    }

    .more-box {
        margin-top: 0.83rem;
        margin-left: 10rem;
        display: flex;
        height: 1.6rem;
        line-height: 1.6rem;
        align-items: center;
    }

    .more-text {
        color: #515151;
        display: flex;
        align-items: center;
    }

    .shopImg {
        margin-top: 0.6rem;
        width: 9.1rem;
        height: 2.1rem;
    }

    .more-img-box {
        display: flex;
        align-items: center;
    }

    .more-img {
        padding-left: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
    }

    .coupon-bg-title-img {
        margin-left: 3.6rem;
        width: 22.5rem;
        height: 3.3rem;
    }

    .coupon-bg-title-content {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        font-weight: bold;
        color: #6A1C1A;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem
    }

    .item-img-box {
        position: relative;
    }


    .item-img {
        padding-left: 2.6rem;
        padding-bottom: 1.6rem;
        width: 10.8rem;
        height: 11.3rem;
    }

    .item-title {
        position: absolute;
        top: 0.75rem;
        left: 3.4rem;
        font-size: 1.3rem;
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #FFF8D3;
    }

    .item-price {
        position: absolute;
        top: 2.8rem;
        left: 3.6rem;
        font-size: 3.8rem;
        color: #E2331D;
    }

    .item-price-box {
        height: 4.1rem;
        width: 9.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item-price-num {
        color: red;
        text-align: center;
    }

    .item-price-text {
        font-size: 1.1rem;
        color: #CE862F;
    }

    .item-name {
        position: absolute;
        top: 8.6rem;
        left: 6.8rem;
        color: #D00000;
        font-weight: bold;
    }

    .coupon-content-bottom-btn-img {
        width: 18.1rem;
        height: 3.9rem;
        margin-left: 5.75rem;
        margin-top: 0.8rem;
    }

    .bg-miao-img-box {
        position: relative;
    }


    .bg-miao-img {
        width: 28.3rem;
        height: 7.8rem;
        margin-left: 0.6rem;
    }

    .miao-content {
        top: 0;
        left: 0;
        position: absolute;
        display: flex;
    }

    .miao-l-img {
        width: 6.6rem;
        height: 6.7rem;
        padding-top: 0.4rem;
        padding-left: 1.66rem;
    }

    .miao-title {
        margin-top: 0.83rem;
        font-size: 1rem;
        color: #333333;
        font-weight: bold;
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .miao-m {
        height: 6.66rem;
        margin-top: 0rem;
        margin-left: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .miao-m-box {

    }

    .miao-intro {
        margin-top: 0.4rem;
        font-size: 1rem;
        color: #FA4E01;
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .miao-price {
        margin-top: 0.4rem;
        margin-left: 0.4rem;
    }

    .miao-price-icon {
        font-size: 0.8rem;
        color: #D00000;
    }

    .miao-price-num {
        font-size: 1.66rem;
        font-weight: bold;
        color: #D00000;
    }

    .miao-price-unit {
        font-size: 0.9rem;
        color: #999999;

    }

    .miao-r {
        width: 10.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .miao-btn {
        background: #FD4800;
        border-radius: 1.8rem;
        width: 6.9rem;
        height: 2.75rem;
        text-align: center;
        line-height: 2.75rem;
        font-size: 1.33rem;
        font-weight: bold;
        color: #FFFFFF;

    }

    .miao-btn-else {
        background: #F56C6C;
        border-radius: 1.8rem;
        width: 7.8rem;
        height: 2.8rem;
        text-align: center;
        line-height: 2.8rem;
        font-size: 0.9rem;
        font-weight: bold;
        color: #FFFFFF;
    }

    .recommend-item {
        width: 28.3rem;
        height: 10rem;
        background: #FDECD3;
        border-radius: 0.8rem;
        display: flex;
        margin-top: 1.25rem;
        margin-left: 0.6rem;
    }

    .rec-l {
        margin-left: 0.75rem;
    }

    .rec-l-img {
        margin-top: 0.4rem;
        width: 9.1rem;
        height: 9.1rem;
    }

    .rec-m-title {
        font-size: 1.3rem;
        font-weight: bold;
        color: #333333;
        padding-top: 0.8rem
    }

    .rec-m-price {
        display: flex;
        justify-content: space-between;
        margin-top: 0.9rem
    }

    .rec-m {
        padding-left: 0.8rem;
        padding-right: 0.75rem;
        height: 9.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rec-m-box {

    }

    .rec-m-intro {
        margin-top: 0.2rem;
        font-size: 1rem;
        color: #FA4E01;
    }

    .rec-m-btn {
        background: #FD4800;
        border-radius: 1.8rem;
        width: 4.8rem;
        height: 1.9rem;
        text-align: center;
        line-height: 1.9rem;
        font-size: 0.9rem;
        font-weight: bold;
        color: #FFFFFF;
    }

    .description {
        margin-top: 1.25rem;
        background: #FFDE98;
        box-shadow: 0 0.16rem 1.2rem 0 rgba(255, 102, 0, 0.20);
        border-radius: 1rem;
    }

    .desc-title {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
        color: #D00000;
        text-align: center;
        text-shadow: 0 0.08rem 0.16rem #FFFDC8;
        padding-top: 1.1rem
    }

    .desc-content {
        padding: 1.4rem 1.9rem;
    }

    .desc-con-title {
        padding: 0.2rem 0;
        font-size: 1rem;
        color: #6A1C1A;
    }

    .intro-text {
        padding-top: 0.3rem;
    }

    .desc-con-intro {
        font-size: 1rem;
        color: #555555;
    }

    .bottom-logo-box {
        margin: 1.25rem 0;
    }

    .bottom-logo {
        width: 16.9rem;
        height: 5.3rem;
        margin-left: 7.1rem;
        margin-bottom: 0.83rem;
    }

</style>
